<template>
  <transition mode="out-in">
    <div
      v-if="active"
      class="modal"
      @click.self="clickSelf"
    >
      <section>
        <div class="modal__body">
          <component
            :is="component"
            v-if="component"
            ref="modal"
            :data="data"
            @close="close"
          />
          <button
            v-if="!customButton"
            class="modal__close"
            title="Закрыть модальное окно"
            @click="close"
          >
            <img
              class="modal-container__closeIcon"
              src="/icon/close.svg"
              alt="x"
            >
          </button>
        </div>
      </section>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalContainer',

  data: () => ({
    active: false,
    component: null,
    data: {},
    customButton: false,
  }),

  mounted() {
    this.$nextTick(() => {
      this.$root.$on('open-modal', this.open);
      this.$root.$on('close-modal', this.close);
    });
  },

  destroyed() {
    this.$root.$on('close-modal', this.close);
    this.$root.$off('open-modal', this.open);
  },

  methods: {
    clickSelf() {
      if (this.customButton) {
        try {
          this.$refs.modal.close();
        } catch (e) {
          // console.log('нет кастомного метода закрытия модалки');
        }
      } else {
        this.close();
      }
    },
    close() {
      document.body.style.overflow = 'auto';
      this.active = false;
    },

    open(component, dataSend, customButton = false) {
      document.body.style.overflow = 'hidden';

      setTimeout(() => {
        this.customButton = customButton;
        this.data = dataSend;
        this.component = component;
        this.active = true;
      }, this.active ? 200 : 0); // 200, для закрытия уже открытого модального окна

      if (this.active) this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .27);

  &__body {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-width: 95%;
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: none;
    transition: transform 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      animation-name: rotate90;
      animation-duration: 200ms;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
