<template>
  <div class="error-page error-404">
    <h1>Страница не найдена</h1>
    <p>
      «Страница не найдена» является стандартным кодом ответа <br>
      протокола передачи гипертекста в компьютерных
      сетевых коммуникациях.
    </p>
    <div class="row center">
      <nuxt-link
        to="/"
        class="btn"
      >
        На главную страницу
      </nuxt-link>
    </div>
    <img
      src="/img/frame-404.svg"
      alt="404"
    >
  </div>
</template>

<script>

export default {
  name: 'ErrorPage404',
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>

.error-page {
  text-align: center;
  padding-top: 1em;
}

img {
  max-width: 100%;
  display: block;
}

h1 {
  margin: 1em 0;
  font-size: 3em;
}

p {
  line-height: 1.5em;
}

button {
  display: inline-block;
  background: none;
  border: 1px solid #3760FB;
  font-size: 17px;
  border-radius: 4px;
  padding: 1em 2em;
  cursor: pointer;
  margin: 1em 0;
}

</style>
