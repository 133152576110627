<template>
  <div class="error-page error-500">
    <h1>Ошибка сервера</h1>
    <p>
      Что-то пошло не так. Это может быть связано с временными проблемами сервера. <br>
      Попробуйте перезагрузить страницу, либо перейти на главную.
    </p>

    <div class="row center">
      <nuxt-link
        to="/"
        class="btn"
      >
        На главную страницу
      </nuxt-link>
    </div>
    <img
      src="/img/frame-500.svg"
      alt="500"
    >
  </div>
</template>

<script>
export default {
  name: 'ErrorPage500',
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>

.error-page {
  text-align: center;
  padding-top: 5em;
}

img {
  max-width: 100%;
}

h1 {
  margin: 1em 0;
  font-size: 3em;
}

p {
  line-height: 1.5em;
}

button {
  display: inline-block;
  background: none;
  border: 1px solid #3760FB;
  font-size: 17px;
  border-radius: 4px;
  padding: 1em 2em;
  cursor: pointer;
  margin: 1em 0;
}

</style>
