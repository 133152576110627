import { render, staticRenderFns } from "./ErrorComponent404.vue?vue&type=template&id=6a078bae&scoped=true"
import script from "./ErrorComponent404.vue?vue&type=script&lang=js"
export * from "./ErrorComponent404.vue?vue&type=script&lang=js"
import style0 from "./ErrorComponent404.vue?vue&type=style&index=0&id=6a078bae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a078bae",
  null
  
)

export default component.exports